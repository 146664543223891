<template>
  <product title="Amazfit 运动速干T恤"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="129"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://j.youzan.com/uoYkKh"
  ></product>
</template>

<script>
import { CDN_URL } from '@/config'
import Product from '../Product'

export default {
  name: 't-shirt',
  components: {
    Product
  },
  data () {
    return {
      slogan: '速干排汗 | 轻盈透气 | 贴合身型',
      colors: [
        '灰蓝色'
      ],
      swiperData: [
        [
          CDN_URL + '/images/product/t-shirt/swiper/1.png',
          CDN_URL + '/images/product/t-shirt/swiper/2.png',
          CDN_URL + '/images/product/t-shirt/swiper/3.png'
        ]
      ],
      relativedProducts: [
      ],
      detailImages: [
        CDN_URL + '/images/product/t-shirt/detail/01.jpg',
        CDN_URL + '/images/product/t-shirt/detail/02.jpg',
        CDN_URL + '/images/product/t-shirt/detail/03.jpg',
        CDN_URL + '/images/product/t-shirt/detail/04.jpg',
        CDN_URL + '/images/product/t-shirt/detail/05.jpg',
        CDN_URL + '/images/product/t-shirt/detail/06.jpg',
        CDN_URL + '/images/product/t-shirt/detail/07.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/product/t-shirt/detail/08.jpg'
      ]
    }
  },
  methods: {
  }
}
</script>

<style>
</style>
